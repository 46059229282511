import request from "@/utils/request";

function moduleList(data) {
  return request({
    url: "/show/module/list",
    method: "post",
    data: data,
  });
}

function moduleAdd(data) {
  return request({
    url: "/show/module/add",
    method: "post",
    data: data,
  });
}

function moduleUpdate(data) {
  return request({
    url: "/show/module/update",
    method: "post",
    data: data,
  });
}

function moduleDel(data) {
  return request({
    url: "/show/module/del",
    method: "post",
    data: data,
  });
}

export { moduleList, moduleAdd, moduleUpdate, moduleDel };
