<template>
  <div>
    <PagingQueryPage
      ref="PagingQueryPage"
      :queryFormState="queryFormState"
      size="middle"
      :bordered="true"
      rowKey="moduleId"
      :columns="columns"
      :pagination="pagination"
      dataUrl="/show/module/list"
      :requestOptions="requestOptions"
    >
      <template #query>
        <a-form-model-item label="客户端名称">
          <a-input v-model="queryFormState.moduleName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="客户端ID">
          <a-input v-model="queryFormState.moduleId"></a-input>
        </a-form-model-item>
      </template>
      <a-button type="primary" icon="plus" slot="action" @click="add"
        >新增</a-button
      >
    </PagingQueryPage>
    <a-modal
      :width="520"
      :visible="modalVisible"
      :title="(isAdd ? '新增' : '编辑') + modalTitle"
      ok-text="确认"
      cancel-text="取消"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="submit"
      @cancel="
        () => {
          modalVisible = false;
        }
      "
    >
      <a-form-model
        ref="modalForm"
        :model="modalFormState"
        :rules="modalRules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item label="名称" prop="moduleName">
          <a-input v-model="modalFormState.moduleName" />
        </a-form-model-item>
        <a-form-model-item label="说明" prop="moduleRemark">
          <a-textarea v-model="modalFormState.moduleRemark" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import PagingQueryPage from "@/components/PagingQueryPage";
import { Modal, notification } from "ant-design-vue";
import { moduleAdd, moduleUpdate, moduleDel } from "@/apis/module";
export default {
  components: { PagingQueryPage },
  data() {
    return {
      queryFormState: {
        moduleName: null,
        moduleId: null,
      },
      requestOptions: { moduleType: "4" },
      columns: [
        {
          title: "序号",
          dataIndex: "",
          width: 80,
          align: "center",
          fixed: "left",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "ID",
          dataIndex: "moduleId",
          width: 200,
          align: "center",
        },
        {
          title: "名称",
          dataIndex: "moduleName",
          width: 200,
          align: "center",
        },
        {
          title: "说明",
          dataIndex: "moduleRemark",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "",
          width: 140,
          align: "center",
          fixed: "right",
          customRender: (record) => {
            return (
              <span class="action-group">
                <a-button
                  type="link"
                  onClick={() => {
                    this.edit(record);
                  }}
                >
                  编辑
                </a-button>
                <a-divider type="vertical" />
                <a-button
                  class="delBtn"
                  type="link"
                  onClick={() => {
                    this.del(record);
                  }}
                >
                  删除
                </a-button>
              </span>
            );
          },
        },
      ],
      pagination: {},
      modalVisible: false, // 弹窗状态
      isAdd: true, // 是否新增
      modalTitle: "客户端", // 弹窗标题
      modalRules: {
        moduleName: [{ required: true, message: "名称必填", trigger: "blur" }],
      }, // 弹窗校验规则
      modalFormState: {
        moduleName: null,
        moduleRemark: null,
      }, // 弹窗值
    };
  },
  methods: {
    add() {
      this.modalVisible = true;
      this.isAdd = true;
      this.modalFormState.moduleId = null;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = null;
        }
      }
    },
    edit(record) {
      this.modalVisible = true;
      this.isAdd = false;
      this.modalFormState.moduleId = record.moduleId;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = record[key];
        }
      }
    },
    submit() {
      this.$refs["modalForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            moduleAdd({
              ...this.requestOptions,
              ...this.modalFormState,
            }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.modalVisible = false;
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "新增成功",
                });
              }
            });
          } else {
            moduleUpdate({ ...this.modalFormState }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.modalVisible = false;
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "编辑成功",
                });
              }
            });
          }
        }
      });
    },
    del(record) {
      Modal.confirm({
        title: `确定要删除该${this.modalTitle}吗？`,
        content: "注意：此操作无法撤销！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          moduleDel({ moduleId: record.moduleId }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              this.$refs["PagingQueryPage"].query();
              notification.success({
                message: "删除成功",
              });
            }
          });
        },
      });
    },
  },
};
</script>

<style></style>
