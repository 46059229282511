<template>
  <div :class="wrpClassName" :style="wrpStyle">
    <a-table
      :size="size"
      :bordered="bordered"
      :loading="loading"
      :rowKey="rowKey"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="
        pagination ? { ...PagingInitState.pagination, ...pagination } : true
      "
      :scroll="{ x: 1200 }"
    >
      <!-- 序号 -->
      <template #index="record, text, index">
        {{ index + 1 }}
      </template>
    </a-table>
  </div>
</template>

<script>
/**
 * PagingQueryTable 表格组件
 * 只关注传入的dataSource和columns，不关注具体怎么获取数据
 * pagination分页参数默认当前第一页，每页十条
 */
import { PagingInitState } from "@/config/enum";
export default {
  name: "PagingQueryTable",
  props: {
    size: { type: String, default: "default" }, // 表格大小 default | middle | small
    bordered: { type: Boolean, default: true }, // 表格是否有边框
    loading: { type: Boolean, default: false }, // 表格是否加载中
    rowKey: { type: [String, Function], default: "id" }, // 表格key 字符串或者函数
    columns: { type: Array, default: () => [] }, // 表格列配置
    dataSource: { type: Array, default: () => [] }, // 表格数据源
    pagination: { type: [Boolean, Object] }, // 分页配置，false时前端分页或者不分页
    wrpClassName: { type: String }, // 最外层元素的class
    wrpStyle: { type: Object, default: () => {} }, // 最外层元素的style
  },
  data() {
    return {
      PagingInitState,
    };
  },
};
</script>
