const PagingInitState = {
  // 请求 “分页参数” 和 “排序参数” 默认值配置
  queryParam: {
    pageSize: "10",
    pageNum: "1",
  },
  // 分页参数默认值配置
  pagination: {
    // defaultCurrent: "1",
    // defaultPageSize: "10",
    // hideOnSinglePage: false,
    // pageSizeOptions: ["10", "30", "50", "100"],
    // showSizeChanger: true,
    // showQuickJumper: true,
    showTotal: (total) => "总条数" + total,
    current: 1,
    pageSize: 10,
    total: 0,
  },
};

const DitiPagingInitState = {
  // 请求 “分页参数” 和 “排序参数” 默认值配置
  queryParam: {
    rowsInPage: "10",
    numOfPage: "1",
  },
  // 分页参数默认值配置
  pagination: {
    // defaultCurrent: "1",
    // defaultPageSize: "10",
    // hideOnSinglePage: false,
    // pageSizeOptions: ["10", "30", "50", "100"],
    // showSizeChanger: true,
    // showQuickJumper: true,
    showTotal: (total) => "总条数" + total,
    current: 1,
    pageSize: 10,
    total: 0,
  },
};

const compnayStatusOptions = [
  { label: "禁用", value: "0" },
  { label: "启用", value: "1" },
];

const roomStatusOptions = [
  { label: "未开播", value: "0" },
  { label: "直播中", value: "1" },
];

const appSystemOptions = [
  { label: "苹果", value: "1" },
  { label: "安卓", value: "2" },
];
const appForceOptions = [
  { label: "否", value: "0" },
  { label: "是", value: "1" },
];
const appStateOptions = [
  { label: "未使用", value: "0" },
  { label: "使用中", value: "1" },
];
export {
  PagingInitState,
  DitiPagingInitState,
  compnayStatusOptions,
  roomStatusOptions,
  appSystemOptions,
  appForceOptions,
  appStateOptions,
};
